export const GUMROAD_EXTERNAL_EMBED_SCRIPT = 'https://gumroad.com/js/gumroad-embed.js'
export const GUMROAD_EXTERNAL_SCRIPT = 'https://gumroad.com/js/gumroad.js'

const DEFAULT_URL = "https://9432604211760.gumroad.com/l/demo"

function GumroadProduct({ url }) {

  return (
    <div className="gumroad-product-embed">
      <a href={url}>Loading...</a>
    </div>
  )
}

function GumroadButton({text = 'Buy on', url = DEFAULT_URL}) {

  return (
    <a className="gumroad-button" href={url}>{text}</a>
  )
}

/**
 * 
 * @param {{type: 'embed' | 'button', text?: string, url?: string}} props 
 * @returns {React.JSX.Element} either button or embedded
 */
export default function GumroadWidget({ type, text, url }) {

  if(type === 'button') {
    return GumroadButton({text, url})
  } else {
    return GumroadProduct({ url })
  }
    
}



