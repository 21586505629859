export default function(response) {
  const { data: { attributes : { theme, socials, credits, pages, logo } } } = response

  return {
    palette: {
      mode: theme.mode,
      primary: {
        main: theme.primary
      },
      secondary: {
        main: theme.secondary
      },
      background: {
        default: theme.secondary,
        paper: theme.secondary,
      },
    },
    typography: theme.fonts?.reduce((accumulator, {type, font, color}) => {
      accumulator[type] = {
        fontFamily: font,
        color,
      }
      return accumulator
    }, {
      fontFamily: theme.baseFont,
    }) || [],
    backgroundColor: theme.background,
    socials,
    credits,
    logoImage: logo.data?.attributes?.url,
    pages: pages?.map(page => ({
      name: page.nome,
      path: page.path,
      component: page.component,
    })) || [],
  }
}