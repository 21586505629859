import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/bangers'
import '@fontsource/do-hyeon'

import { ErrorBoundary } from "react-error-boundary"

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === 'production') {
  console.debug = () => {}
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <App />
    </ErrorBoundary>
  </React.StrictMode>
)

