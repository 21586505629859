/**
 * @typedef {'home'|'prodotti'|'about'|'app'} FetchType
 */

import { home, app, about, prodotti } from "./mapper"

// eslint-disable-next-line no-undef
const { REACT_APP_BASE_URL } = process.env

/**
 * @param {FetchType} type 
 */
function getUrl(type) {
  switch(type) {
  case 'home':
    return `${REACT_APP_BASE_URL}/api/home-page?populate[0]=vetrina&populate[1]=evento&populate[2]=evento.descrizione`
  case 'prodotti':
    return `${REACT_APP_BASE_URL}/api/prodottis?populate[0]=categoria&populate[1]=categoria.descrizione&populate[2]=image`
  case 'about':
    return `${REACT_APP_BASE_URL}/api/about?populate[0]=viscoses&populate[1]=descrizione&populate[2]=viscoses.biografia&populate[3]=viscoses.picture&populate[4]=viscoses.opere`
  case "app":
    return `${REACT_APP_BASE_URL}/api/website?populate[0]=theme&populate[1]=theme.fonts&populate[2]=socials&populate[3]=credits&populate[4]=pages&populate[5]=logo`
  default:
    return ''
  }
}

/**
 * 
 * @param {{data: unknown}} response 
 * @param {FetchType} type
 * @returns {import("./mapper/home").HomeResponse | unknown}
 */
function getDataFromResponse(response, type) {
  switch(type) {
  case 'home':
    return home(response)
  case 'prodotti': 
    return prodotti(response)
  case "about":
    return about(response)
  case 'app':
    return app(response)
  default:
    console.debug('received unmapped data %o from type %s', response, type)
    return []
  }
}

/**
 * 
 * @param {{type: FetchType, signal: AbortSignal}} params 
 * @returns {Promise<unknown>} 
 */
export async function strapiFetch({ type, signal }) {
  try {
    const response = await fetch(getUrl(type), { signal })
    return getDataFromResponse(await response.json(), type)
  } catch (error) {
    if (error.name !== 'AbortError') {
      throw error
    } else {
      return []
    }
  }
    
}
