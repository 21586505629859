export default function(response) {
  const { data: {attributes: { descrizione, viscoses }}} = response
      
  return {
    description: descrizione,
    people: viscoses?.data?.map(({attributes}) => ({
      title: attributes.nickname,
      subheader: attributes.descrizione,
      bio: attributes.biografia,
      image: attributes?.picture?.data?.attributes?.url,
      images: attributes?.opere?.data?.map(({attributes}) => ({
        imgPath: attributes.url,
        alt: attributes.alternativeText
      })) || [],
    })) || []
  }
}