import * as React from 'react'
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Avatar } from '@mui/material'

const ExpandMore = styled((props) => {
  const { ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

export default function ExpandedCard({ 
  title, subheader, children, isExpanded, image, avatar, 
  action , width = '50vh', cursor = 'auto', onClick
}) {
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <Card sx={{ width, cursor }} onClick={onClick}>
      <CardHeader
        action={ action &&
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        avatar={
          avatar ? (<Avatar sx={{ color: 'red' }} aria-label="recipe">
            R
          </Avatar>) : null
        }
        title={title}
        subheader={subheader}
      />
      <CardMedia
        component="img"
        height="194"
        image={image}
        alt={title}
      />
      <CardContent>
        {!isExpanded && children}
      </CardContent>
      <CardActions disableSpacing>
        {
          isExpanded && <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }   
      </CardActions>
      {isExpanded && <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {isExpanded && children}
        </CardContent>
      </Collapse> }
    </Card>
  )
}
