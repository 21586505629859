export default function(response) {
  const {data: prodotti} = response
  return prodotti?.reduce((accumulator, { attributes }) => {
    const {
      categoria: { data: { attributes: categoria } },
      image,
      titolo,
      gumroadLink,
      descrizione,
    } = attributes
    
    const existingCategory = accumulator.find(item => item.name === categoria.nome)
    if(!existingCategory) {
      accumulator.push({
        name: categoria.nome,
        description: categoria.descrizione,
        items: [{
          title: titolo,
          image: image?.data?.attributes?.url,
          link: gumroadLink,
          description: descrizione,
        }]
      }) 
    } else {
      existingCategory.items.push({
        title: titolo,
        image: image?.data?.attributes?.url,
        link: gumroadLink,
        description: descrizione,
      })
    }
      
    return accumulator
  }, []) || []
}