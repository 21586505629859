import { Link, Typography } from '@mui/material'
import { BlocksRenderer } from '@strapi/blocks-react-renderer'

export default function StrapiRender({content, color}) {
  return (
    <BlocksRenderer
      content={content || []}
      blocks={{
        // You can use the default components to set class names...
        paragraph: ({ children }) => <Typography color={color} variant='body1' >{children}<br/></Typography>,
        // ...or point to a design system
        heading: ({ children, level }) => {
          switch (level) {
          case 1:
            return <Typography variant="h1">{children}</Typography>
          case 2:
            return <Typography variant="h2">{children}</Typography>
          case 3:
            return <Typography variant="h3">{children}</Typography>
          case 4:
            return <Typography variant="h4">{children}</Typography>
          case 5:
            return <Typography variant="h5">{children}</Typography>
          case 6:
            return <Typography variant="h6">{children}</Typography>
          default:
            return <Typography variant="h1">{children}</Typography>
          }
        },
        // For links, you may want to use the component from your router or framework
        link: ({ children, url }) => <Link target="_blank" href={url}>{children}</Link>,
        list: ({ children} ) => <Typography color={color} align='inherit' variant='body1' >{children}</Typography>,
        image: ({image}) => <img src={image.url} alt={image.alternativeText} width='100%' />
      }}
      modifiers={{
        bold: ({ children }) => <strong>{children}</strong>,
        italic: ({ children }) => <span className="italic">{children}</span>,
      }}
    />
  )
}