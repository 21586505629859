import * as React from 'react'
import { strapiFetch } from '../lib/fetch'
import { getItemFromStorage, saveItemToStorage } from '../lib/storage'

// eslint-disable-next-line no-undef
const DEFAULT_TIMESTAMP = process.env.REACT_APP_DEFAULT_TIMESTAMP
/**
 * 
 * @param {{STORAGE_ITEM: import('../lib/fetch').FetchType, fetchCallBack: Function, timestamp?: number }} props 
 */
export default function Page({STORAGE_ITEM, fetchCallBack, timestamp = DEFAULT_TIMESTAMP}) {
  const controller = React.useRef(null)
  const [isLoading, setIsLoading] = React.useState(true)
  const fetch = () => {
    const storageData = getItemFromStorage(STORAGE_ITEM)
    
    if(!storageData) {
      const requestController = new AbortController()
      strapiFetch({
        signal: requestController.signal,
        type: STORAGE_ITEM
      }).then((response) => {
        if(response) {
          saveItemToStorage(STORAGE_ITEM, response, timestamp)
          fetchCallBack(response)
          setIsLoading(false)
        }
        controller.current = requestController
      })
    } else {
      console.debug('found %s data %o in storage', STORAGE_ITEM, storageData)
      fetchCallBack(storageData)
      setIsLoading(false)
    }
  }

    
  React.useEffect(() => {
    console.debug('use effect on page:', STORAGE_ITEM)
    fetch()
    // abort request on unmount
    return () => {
      console.debug('unmount on page:', STORAGE_ITEM, controller)
      controller.current?.abort()
    }
  }, [])
    
  console.debug('rendering page:', STORAGE_ITEM)
  return { isLoading }
}