import { CircularProgress } from "@mui/material"

export default function Loading() {
  return <CircularProgress sx={{
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: [
      'translate(-50%, -50%)',
      '-webkit-translate(-50%, -50%)',
      '-moz-translate(-50%, -50%)',
      '-ms-translate(-50%, -50%)',
    ]
  }} />
}