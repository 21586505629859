import {motion} from 'framer-motion'

export default function Animation({hover = false, children }) {
  return(
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.8,
        delay: 0.5,
        ease: [0, 0.71, 0.2, 1.01]
      }}
      whileHover={hover ? { scale: 1.1 } : {}}
      whileTap={hover ? { scale: 0.9 } : {}}
    >
      {children}
    </motion.div>
  )
}