import * as React from 'react'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'

import { Paper, createSvgIcon } from '@mui/material'
import StrapiRender from './StrapiRender'

export default function SimpleBottomNavigation({items = [], content}) {

  return (
    <Paper sx={{ bottom: 0, left: 0, right: 0 }} elevation={3}>
      <StrapiRender content={content?.text} color={content?.color}/>
      <BottomNavigation
        showLabels
      >
        {
          items.map((item, index) => {
            const Icon = createSvgIcon(<path d={item.svg}/>, item.name)
            return (
              <BottomNavigationAction 
                key={index} href={item.link} target="_blank" 
                label={item.name} icon={<Icon />} 
              />
            )})
        }
      </BottomNavigation>
      <StrapiRender content={[{"type":"paragraph","children":[{"type":"text","text":"Website by "},{"type":"link","url":"https://github.com/flower-of-the-bridges/","children":[{"type":"text","text":"flower-of-the-bridges"}]},{"text":"","type":"text"}]}]}/>
    </Paper>
  )
}