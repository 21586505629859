import { Box, Grid, Stack, Typography, } from "@mui/material"
import ExpandedCard from "../components/Card"
import * as React from 'react'
import StrapiRender from "../components/StrapiRender"
import BasicModal from "../components/Modal"
import Page from "./Page"
import SwipeableTextMobileStepper from "../components/SwipeStepper"
import Loading from "../components/Loading"
import Animation from "../components/Animation"

export default function Persone() {
  const [data, setData] = React.useState(null)
  const [selectedPeople, setSelectedPeople] = React.useState(null)
  const [modal, setModal] = React.useState(false)

  const openModal = (people) => {
    setSelectedPeople(people)
    setModal(true)
  }

  const { isLoading } = Page({STORAGE_ITEM: 'about', fetchCallBack: setData})

  return(
    <Grid container spacing={{ xs: 2, md: 3, sm: 4, }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{ pt: 15, pb: 1 }}>
      <Grid item xs={4} md={3} sm={12}>
        <Box sx={{pt: {xs: 0, md: 15, sm: 0 }}}>
          <Animation>
            <StrapiRender color={data?.description?.color} content={data?.description?.text || []} />
          </Animation>
        </Box>
      </Grid>
      <Grid item xs={6} md={9} sm={12} sx={{ overflow: 'auto', maxHeight: '100vh'}}>
        {
          !isLoading ? <Stack spacing={{ xs: 1, sm: 2, md: 1 }} justifyContent='space-evenly' alignContent='center' direction="row" useFlexGap flexWrap="wrap">
            {
              data?.people?.map(({title, subheader, image, bio, images }, index) => (
                <Animation hover key={title+index}>
                  <ExpandedCard cursor="pointer"
                    title={title} 
                    subheader={subheader} image={image}
                    onClick={() => openModal({title, subheader, image, bio, images }) }
                  />
                </Animation>
              )
              )}
          </Stack> : <Loading/>
        }
      </Grid>
      <BasicModal title={selectedPeople?.title} open={modal} onClose={() => setModal(false)}>
        <Box sx={{textAlign: 'center'}}>
          <Animation>
            <Typography variant="subtitle1">{selectedPeople?.subheader}</Typography>
            {selectedPeople?.images?.length > 0 && <SwipeableTextMobileStepper paddingTop={0} maxHeight={350} width="fit-content" images={selectedPeople?.images}/>}
            <Box sx={{ overflow: 'auto', maxHeight: '20vh'}}>
              <StrapiRender content={selectedPeople?.bio?.text}/>
            </Box>
          </Animation>
        </Box>
      </BasicModal>
            
    </Grid>
  )
}