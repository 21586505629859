import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

function TabPanel(props) {
  const { children, ...other } = props

  return (
    <div
      role="tabpanel"
      id={`tabpanel`}
      aria-labelledby={`tab`}
      {...other}
      style={{ overflowX: 'auto', maxHeight: '80vh'}}
    >
      <Box sx={{ display: 'flex', pb: 2, justifyContent: 'space-evenly', flexDirection: 'row', gap: 2, flexWrap: 'wrap'}}>
        {children}
      </Box>
    
    </div>
  )
}

/**
 * @param {{items: unknown[], sx : import('@mui/system').SxProps}} props
 */
function VerticalTab({ items, children, onChangeItem, sx }) {
    
  const [value, setValue] = React.useState(0)

  const handleChange = (event, index) => {
    const selectedItem = items[index]
    onChangeItem && onChangeItem(selectedItem)
    setValue(index)
  }

  React.useEffect(() => {
    onChangeItem && items && onChangeItem(items[value])
  }, [items])

  return (
    <Box
      sx={sx}
    >
      <Tabs
        orientation="horizontal"
        centered
        value={value}
        onChange={handleChange}
        sx={{ borderColor: 'divider' }}
      >
        {
          items.map(({ name }, index) => (<Tab label={name} key={name+index} />))
        }
      </Tabs>
      <TabPanel>
        {children}
      </TabPanel>
    </Box>
  )
}

export default VerticalTab