/* eslint-disable no-undef */
export function getItemFromStorage(item) {
    
  if(process.env.NODE_ENV === 'production') {
    const { data, timestamp } = JSON.parse(localStorage.getItem(item) || '{}')
    if(!data || !timestamp) {
      return null
    }
    if(Number(timestamp) - Date.now() < 0) {
      return null
    }
    return data
  } else {
    return null
  }
}

/**
 * 
 * @param {string} key 
 * @param {unknown} data 
 * @param {number} timestamp 
 */
export function saveItemToStorage(key, data, timestamp) {
  localStorage.setItem(
    key, 
    JSON.stringify({
      data, timestamp: Date.now() + Number(timestamp) 
    }
    ))
}