import * as React from 'react'
import { Box, Grid, Typography } from "@mui/material"
import SwipeableTextMobileStepper from "../components/SwipeStepper"
import StrapiRender from '../components/StrapiRender'
import Page from './Page'
import Loading from '../components/Loading'
import Animation from '../components/Animation'


export default function Home() {
  const [data, setData] = React.useState({event: null, description: [], images: []})

  const fetchHomepage = ({ event, images, description}) => {
    
    if(event) {
      setData((previous) => ({...previous, event}))
    }
    if(images) {
      setData((previous) => ({...previous, images}))
    }
    if(description) {
      setData((previous) => ({...previous, description}))
    }
  }

  const { isLoading } = Page({STORAGE_ITEM: 'home', fetchCallBack: fetchHomepage})
    
  return(
    <Grid container spacing={{ xs: 2, md: 3, sm: 4, }} columns={{ xs: 4, sm: 8, md: 12 }}>
      <Grid item xs={4} sm={8} md={12}>
        <Animation>
          <SwipeableTextMobileStepper images={data.images}/> 
        </Animation>
      </Grid>
      <Grid item xs={0} md={2} sm={0}></Grid>
      <Grid item xs={4} sm={8} md={8}>
        {!isLoading ? <Box component="section" sx={{ p: 2, borderRadius: 1 }}>
          <Animation>
            <StrapiRender content={data.description}/>    
          </Animation>
        </Box> : <Loading/>}
      </Grid>
      <Grid item xs={0} md={2} sm={0}></Grid>
      <Grid item xs={0} md={4} sm={0}></Grid>
      <Grid item xs={12} md={4} sm={8}>
        <Animation>
          <Box component="section" sx={{ p: 2, borderRadius: 1, bgcolor: 'primary.main' }}>
            <Typography variant='h2'>{data.event?.titolo}</Typography>
            <Typography variant='caption'>{data.event?.data}</Typography>
            <StrapiRender color={data.event?.descrizione?.color} content={data.event?.descrizione?.text || []}/> 
          </Box>
        </Animation>
      </Grid>
      <Grid item xs={0} md={4} sm={0}></Grid>
    </Grid>
  )
} 