/**
 * @typedef {{event: unknown, images: unknown[], description: unknown}} HomeResponse
 * @param {unknown} response 
 * @returns {HomeResponse}
 */
export default function(response) {
  const { data: { attributes: {evento, vetrina, introduzione } } } = response
  return {
    event: evento.data.attributes,
    images: vetrina.data?.map(({attributes}) => ({
      imgPath: attributes.url,
      alt: attributes.alternativeText
    })),
    description: introduzione,
  }
}