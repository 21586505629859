import * as React from 'react'
import { Grid } from "@mui/material"
import ExpandedCard from "../components/Card"
import GumroadWidget from "../components/GumRoad"
import VerticalTab from '../components/VerticalTab'
import StrapiRender from '../components/StrapiRender'
import Page from './Page'
import Loading from '../components/Loading'
import Animation from '../components/Animation'

export default function Fumetti() {
  const [categories, setCategories] = React.useState([])
  const [category, setCategory] = React.useState(null)

  const {isLoading} = Page({STORAGE_ITEM: 'prodotti', fetchCallBack: (categories) => {
    setCategories(categories)
    !category && setCategory(categories[0])
  }})

  return(

    <Grid container spacing={{ xs: 2, md: 3, sm: 4, }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{ p: 1, pt: 15, pb: 1 }}>
      <Grid item xs={4} md={12} sm={8} >
        <Animation>
          <StrapiRender color={category?.description?.color} content={category?.description?.text || []}/> 
        </Animation>
      </Grid>
      <Grid item xs={4} md={12} sm={8} sx={{height: '100vh'}}>
        {
          !isLoading ?
            <VerticalTab sx={{ alignContent: 'center', borderRadius: 1, bgcolor: 'secondary.main' }} items={categories} onChangeItem={(newCategory) => {
              newCategory?.name !== category?.name && setCategory(newCategory)
            }}>
              {
                category?.items.map(({title, subheader, description, image, link}, index) => (
                  <Animation hover key={title+index}>
                    <ExpandedCard title={title} subheader={subheader} image={image}>
                      <StrapiRender content={description || []}/> 
                      {link && <GumroadWidget type='button' url={link}/>}
                    </ExpandedCard>
                  </Animation>)
                )}
            </VerticalTab>
            : <Loading/>
        }
      </Grid>
    </Grid>
  )
} 