import * as React from 'react'
import ResponsiveAppBar from './components/AppBar'
import SimpleBottomNavigation from './components/BottomNavigation'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { Box, GlobalStyles } from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material'
import Page from './pages/Page'

const timestamp = 14 * 24 * 60 * 60 * 1000

import Home from "./pages/Home"
import Fumetti from "./pages/Fumetti"
import Persone from "./pages/Persone"
import Loading from './components/Loading'

function getElementFromComponent(component) {
  switch(component) {
  case 'Home':
    return <Home/>
  case 'Negozio':
    return <Fumetti/>
  case 'About':
    return <Persone/>
  }
}

function App() {

  const [theme, setTheme] = React.useState(null)
  const [pages, setPages] = React.useState([])
  const [router, setRouter] = React.useState(null)
  const [socials, setSocials] = React.useState(null)
  const [credits, setCredits] = React.useState(null)
  const [logo, setLogo] = React.useState(null)
  const [backgroundColor, setBackgroundColor] = React.useState(null)
  

  const updateAppInfo = (appInfo) => {
    const {palette, typography, socials, credits, backgroundColor, logoImage, pages} = appInfo
    
    setTheme(createTheme({
      palette,
      typography,
    }))
    setRouter(createBrowserRouter(pages.map(({path, component})=> ({
      path, 
      element: getElementFromComponent(component)
    }))))
    setBackgroundColor(backgroundColor)
    setLogo(logoImage)
    setSocials(socials)
    setCredits(credits)
    setPages(pages)
  }

  const { isLoading } = Page({STORAGE_ITEM: 'app', fetchCallBack: updateAppInfo, timestamp, })
  
  return (
    <div style={{textAlign: 'center'}}>
      {isLoading === false ?  <ThemeProvider theme={theme}>
        <GlobalStyles
          styles={{
            body: { backgroundColor },
          }}
        />
        <header>
          <ResponsiveAppBar logo={logo} pages={pages}></ResponsiveAppBar>
        </header>
        <Box sx={{ height: '100vh'}}>
          <RouterProvider router={router} />
          <SimpleBottomNavigation items={socials} content={credits}/>
        </Box>
    
      </ThemeProvider> : <Loading/>}
    </div>
  )
}

export default App
